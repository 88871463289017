import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal as RBModal } from 'react-bootstrap';

const Modal = ({
  backdrop,
  dialogClassName,
  open: openProp,
  onHide,
  children,
  isShowCloseButton,
  centered,
}) => {
  const [open, setOpen] = useState(openProp);

  useEffect(() => {
    if (openProp !== open) setOpen(openProp);
  }, [openProp, open]);

  function closeModal(e) {
    setOpen(false);
    onHide();
  }

  return (
    <RBModal
      backdrop={backdrop}
      dialogClassName={dialogClassName}
      show={open}
      onHide={closeModal}
      centered={centered}
    >
      <RBModal.Body style={{ padding: '3rem 0' }}>
        {children}
        {isShowCloseButton && (
          <button
            title="Close (Esc)"
            type="button"
            className="mfp-close"
            onClick={closeModal}
          >
            <span>×</span>
          </button>
        )}
      </RBModal.Body>
    </RBModal>
  );
};

const ModalTitle = ({ children }) => {
  return (
    <RBModal.Title style={{ marginBottom: 0 }}>
      {children}
    </RBModal.Title>
  );
};

const ModalHeader = ({ children }) => {
  return (
    <RBModal.Header
      style={{
        borderBottom: 0,
        justifyContent: 'center',
        paddingTop: '1.5rem',
        textAlign: 'center',
      }}
    >
      {children}
    </RBModal.Header>
  );
};

const ModalBody = ({ children, style = {} }) => {
  return (
    <RBModal.Body
      style={{
        textAlign: 'center',
        padding: '2rem',
        paddingTop: '0',
        ...style,
      }}
    >
      {children}
    </RBModal.Body>
  );
};

const ModalFooter = ({ children }) => {
  return (
    <RBModal.Footer
      style={{
        borderTop: 0,
        justifyContent: 'center',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      {children}
    </RBModal.Footer>
  );
};

Modal.propTypes = {
  backdrop: PropTypes.oneOf(['static', true, false]),
  children: PropTypes.node.isRequired,
  dialogClassName: PropTypes.string,
  open: PropTypes.bool,
  onHide: PropTypes.func,
  isShowCloseButton: PropTypes.bool,
  centered: PropTypes.bool,
};
Modal.defaultProps = {
  isShowCloseButton: true,
  centered: false,
};

Modal.Title = ModalTitle;
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
