/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Link from 'components/common/link';

import { aboutUsItems, cateringItems, mealPreps } from './utils';
import { useAuth } from 'lib/auth';
import { routePaths } from 'appConfig';

function MobileMainNav(props) {
  const { session, signOut } = useAuth();

  return (
    <nav className="mobile-nav">
      <ul className="mobile-menu">
        <li>
          <Link to={'/'}>
            <>Home</>
          </Link>
        </li>

        <li>
          <Link to={routePaths.CATEGORIES}>
            <>Order Now</>
          </Link>
        </li>

        <li>
          <a
            className="text-white"
            style={{ textDecoration: 'none' }}
          >
            About Us
          </a>

          <ul>
            {aboutUsItems.map((item) => (
              <li>
                <Link to={item.to} activeClassName="active">
                  <>{item.label}</>
                </Link>
              </li>
            ))}
          </ul>
        </li>

        <li>
          <a
            className="text-white"
            style={{ textDecoration: 'none' }}
          >
            Meal Prep
          </a>

          <ul>
            {mealPreps.map((item) => (
              <li>
                <Link to={item.to} activeClassName="active">
                  <>{item.label}</>
                </Link>
              </li>
            ))}
          </ul>
        </li>

        <li>
          <a
            className="text-white"
            style={{ textDecoration: 'none' }}
          >
            Catering
          </a>

          <ul>
            {cateringItems.map((item) => (
              <li>
                <Link to={item.to} activeClassName="active">
                  <>{item.label}</>
                </Link>
              </li>
            ))}
          </ul>
        </li>

        <li>
          <Link to={routePaths.YB_EXPRESS} target="_blank">
            <>YB Express</>
          </Link>
        </li>

        <li>
          <Link to={routePaths.CART}>
            <>Cart</>
          </Link>
        </li>

        {session ? (
          <>
            <li>
              <Link to={routePaths.ACCOUNT}>
                <>My Account</>
              </Link>
            </li>
            <li>
              <a className="text-white" onClick={() => signOut()}>
                Sign Out
              </a>
            </li>
          </>
        ) : (
          <li>
            <Link to={routePaths.LOGIN}>
              <>Sign In / Register</>
            </Link>
          </li>
        )}
      </ul>
    </nav>
  );
}

export default MobileMainNav;
