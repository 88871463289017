import { Button, Modal } from 'components/common';
import { COUPON_CODE_QUERY_PARAM } from 'lib/coupons';
import { formatDate } from 'lib/datetime';
import { useRouter } from 'next/router';
import useMaybeApplySavedCouponCodeToCart from './useMaybeApplySavedCouponCodeToCart';

const MaybeApplySavedCouponCodeToCart = () => {
  const {
    showNotificationDialog,
    setShowNotificationDialog,
    coupon,
  } = useMaybeApplySavedCouponCodeToCart();

  const { query, pathname, replace } = useRouter();
  const params = new URLSearchParams(query);

  const onCloseNotification = () => {
    params.delete(COUPON_CODE_QUERY_PARAM);
    replace(
      {
        pathname,
        query: params.toString(),
      },
      undefined,
      { shallow: true },
    );
    setShowNotificationDialog(false);
  };

  const getOffValue = (value, isPercentage) => {
    if (isPercentage) return `${value}%`;
    return `$${value}`;
  };

  return (
    <Modal
      open={showNotificationDialog && coupon}
      onHide={onCloseNotification}
      backdrop="static"
      bodyStyle={{ padding: 0 }}
    >
      <Modal.Body style={{ paddingTop: 0, paddingBottom: 0 }}>
        <div className="tw-flex tw-flex-col tw-items-center tw-text-center">
          <div className="tw-space-y-2">
            <h2 className="tw-text-3xl tw-font-medium tw-text-white-600 tw-mb-0">
              You've unlocked
            </h2>
            <p className="tw-text-5xl tw-font-bold tw-tracking-tight tw-text-primary tw-mb-0">
              {getOffValue(
                coupon?.discount_value,
                coupon?.is_percent,
              )}{' '}
              off
            </p>
          </div>
          <div className="tw-mt-4 tw-space-y-3">
            <p className="tw-text-white-600 tw-mb-0">
              Bromo code automatically applied at checkout.
            </p>
            {coupon?.end_at && (
              <p className="tw-text-xl tw-text-[#777] tw-mb-0">
                Expires on{' '}
                {formatDate(coupon.end_at, {
                  format: 'MMMM DD, YYYY',
                })}
                .
              </p>
            )}
          </div>
          <Button
            onClick={onCloseNotification}
            className="tw-mt-6 tw-font-medium tw-w-full tw-rounded-md"
          >
            <span>OH YAY!</span>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MaybeApplySavedCouponCodeToCart;
