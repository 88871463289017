import { useEffect, useState } from 'react';
import {
  getSavedCouponCode,
  useApplyCouponCodeFormContext,
  removeSavedCouponCode,
} from 'lib/coupons';
import { useDiscountContext, DISCOUNT_METHOD } from 'lib/discount';

import useFetchCart from './CartContext/useFetchCart';

const useMaybeApplySavedCouponCodeToCart = () => {
  const { cart, applyCouponToCart } = useFetchCart();
  const {
    setCouponCode,
    setCouponCodeError,
  } = useApplyCouponCodeFormContext();
  const { setMethod: setDiscountMethod } = useDiscountContext();

  const [
    showNotificationDialog,
    setShowNotificationDialog,
  ] = useState(false);

  const [coupon, setCoupon] = useState(null);

  useEffect(() => {
    const maybeApplySavedCouponCodeToCart = async () => {
      try {
        const savedCouponCode = getSavedCouponCode();

        if (savedCouponCode) {
          setDiscountMethod(DISCOUNT_METHOD.COUPON);

          setCouponCode(savedCouponCode);

          const newCart = await applyCouponToCart(
            savedCouponCode,
            true,
          );

          setCouponCode('');
          setCouponCodeError(null);
          removeSavedCouponCode();
          setCoupon(newCart?.discounts?.coupons?.[0]);
          setShowNotificationDialog(true);
        }
      } catch (err) {
        // setCouponCodeError(err.errors.message);
        setShowNotificationDialog(false);
        setCoupon(null);
      }
    };

    if (cart) maybeApplySavedCouponCodeToCart();
  }, [
    cart,
    setCouponCode,
    setCouponCodeError,
    setDiscountMethod,
    applyCouponToCart,
  ]);

  return {
    showNotificationDialog,
    setShowNotificationDialog,
    coupon,
  };
};

export default useMaybeApplySavedCouponCodeToCart;
