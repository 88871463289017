import { useCallback, useContext } from 'react';

import * as analytics from 'lib/analytics';
import { useAuth } from 'lib/auth';
import { getErrorMessage } from 'lib/httpClient';
import { useCRUD } from 'lib/swr-crud';

import * as api from '../api';
import { GuestCart, UserCart } from '../CartSources';
import { AddToCartError } from '../errors';
import CartContext from './CartContext';
import SessionCart from '../SessionCart';

const useFetchCart = () => {
  const { loading, session } = useAuth();

  const getCartSource = () =>
    session ? new UserCart() : new GuestCart();

  const fetchCart = async (session) => {
    try {
      let cart = await SessionCart.current({ isGuest: !session });

      return cart;
    } catch (err) {
      throw err;
    }
  };

  const fetchAndUseCart = async (session) => {
    const cart = await fetchCart(session);

    SessionCart.use(cart);

    return cart;
  };

  const {
    addUpdatingCartLineId,
    removeUpdatingCartLineId,
    addRemovingCartLineId,
    removeRemovingCartLineId,
  } = useContext(CartContext);

  const { isLoading, data: cart, error, mutate } = useCRUD(
    loading ? null : ['cart', session?.accessToken],
    async (_key, session) => {
      return await fetchAndUseCart(session);
    },
  );

  const refreshCart = useCallback(() => mutate(), [mutate]);

  const addToCart = async ({
    product,
    productVariantId,
    quantity,
    addonOptionValueIds,
  }) => {
    try {
      const cartId = SessionCart.cartId;

      const newCart = await api.addToCart({
        cartId,
        productVariantId,
        quantity,
        addonOptionValueIds,
      });

      mutate(newCart);

      return newCart;
    } catch (err) {
      throw new AddToCartError({ product, serverError: err });
    }
  };

  const updateCartLineQuantity = useCallback(
    async (cartLine, quantity) => {
      const cartId = SessionCart.cartId;

      const {
        id: cartLineId,
        product_variant_id: productVariantId,
        addon_option_values: addonOptionValues,
      } = cartLine;

      try {
        addUpdatingCartLineId(cartLineId);

        const newCart = await api.updateCartLineQuantity({
          cartId,
          productVariantId,
          quantity,
          addonOptionValueIds: addonOptionValues.map(
            (optionValue) => optionValue.id,
          ),
        });

        mutate(newCart);
      } catch (err) {
        throw err;
      } finally {
        removeUpdatingCartLineId(cartLineId);
      }
    },
    [addUpdatingCartLineId, mutate, removeUpdatingCartLineId],
  );

  const updateCartLineVariant = useCallback(
    async (cartLine, newProductVariantId) => {
      const cartId = SessionCart.cartId;

      const { id: cartLineId, quantity } = cartLine;

      try {
        addUpdatingCartLineId(cartLineId);
        await api.addToCart({
          cartId,
          productVariantId: newProductVariantId,
          quantity,
        });
        await api.removeCartLine(cartId, cartLineId);

        await refreshCart();
      } catch (err) {
        throw getErrorMessage(err);
      } finally {
        removeUpdatingCartLineId(cartLineId);
      }
    },
    [addUpdatingCartLineId, refreshCart, removeUpdatingCartLineId],
  );

  const removeCartLine = useCallback(
    async (cartLine) => {
      const cartId = SessionCart.cartId;

      const {
        id: cartLineId,
        product,
        product_variant_id,
        quantity,
        total,
      } = cartLine;

      addRemovingCartLineId(cartLineId);

      await api.removeCartLine(cartId, cartLineId);

      await refreshCart();

      removeRemovingCartLineId(cartLineId);

      analytics.removeFromCart({
        product,
        productVariant: product.variants.find(
          (variant) => variant.id === product_variant_id,
        ),
        quantity,
        total,
      });
    },
    [addRemovingCartLineId, refreshCart, removeRemovingCartLineId],
  );

  const applyCouponToCart = useCallback(
    async (couponCode, forceApply = false) => {
      try {
        const cartId = SessionCart.cartId;

        const newCart = await api.applyCouponToCart(
          cartId,
          couponCode,
          forceApply,
        );

        mutate(newCart);
        return newCart;
      } catch (err) {
        throw getErrorMessage(err);
      }
    },
    [mutate],
  );

  const removeCouponFromCart = useCallback(
    async (couponCode) => {
      try {
        const cartId = SessionCart.cartId;

        await api.removeCouponFromCart(cartId, couponCode);

        await refreshCart();
      } catch (err) {
        throw getErrorMessage(err);
      }
    },
    [refreshCart],
  );

  const applyLoyaltyPointsToCart = useCallback(async () => {
    try {
      const cartId = SessionCart.cartId;

      const newCart = await api.applyLoyaltyPointsToCart(cartId);

      mutate(newCart);
    } catch (err) {
      throw getErrorMessage(err);
    }
  }, [mutate]);

  const removeLoyaltyPointsFromCart = useCallback(async () => {
    try {
      const cartId = SessionCart.cartId;

      await api.removeLoyaltyPointsFromCart(cartId);

      await refreshCart();
    } catch (err) {
      throw getErrorMessage(err);
    }
  }, [refreshCart]);

  const popCartInUse = useCallback(async () => {
    try {
      const cartId = SessionCart.cartId;

      await api.removeCart(cartId);

      await refreshCart();
    } catch (err) {
      throw err;
    }
  }, [refreshCart]);

  return {
    isLoading,
    cart,
    setCart: mutate,
    error,
    refreshCart,
    addToCart,
    updateCartLineQuantity,
    updateCartLineVariant,
    removeCartLine,
    applyCouponToCart,
    removeCouponFromCart,
    applyLoyaltyPointsToCart,
    removeLoyaltyPointsFromCart,
    popCartInUse,
    getCartSource,
  };
};

export default useFetchCart;
